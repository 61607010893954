<template>
    <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">STORE SETTINGS</div>
            </div>
        </div>
        <form action="" class="box" @submit.prevent="submitForm" v-if="getSettingUserAuthorized('settingsAuthorizedEmails')">
        <section class="modal-card-body">
            <div class="columns">
                <div class="column is-6">

                    <div class="columns">
                        <div class="column is-6">
                            Settings Authorized <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.settingsAuthorizedEmails.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>
                    
                    <div class="columns">
                        <div class="column">
                            Cost of Goods Sold Method
                        </div>
                        <div class="column">
                            <b-select v-model="settingsValues.profitCalculationMethod.value">
                                <option value="Margin">Margin</option>
                                <option value="Markup">Markup</option>
                            </b-select>
                            <span class="is-size-7 has-text-info has-text-right">{{ profitCalculationMethodMessage }}</span>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Credit Card Processor
                        </div>
                        <div class="column is-6">    
                            <b-select v-model="settingsValues.ccVendorId.value" v-if="vendors.length > 0">
                                    <option v-for="vendor in vendors" :key="vendor.id + Math.random()" :value="vendor.id">{{ vendor.name }}</option>
                                </b-select>
                                <div v-else>Please add your vendors first. {{vendors.length}}</div>
                        </div>
                    </div>
                    <div class="columns">
                        
                        <div class="column is-6">
                            Shift Edit Allowed Days - Manager
                        </div>
                        <div class="column is-6">    
                            <b-numberinput placeholder="Seq#" compact
                                v-model="settingsValues.shiftManagerAllowDays.value"
                                class="lottery-seq-input"
                                size="is-small"
                                :min="2"
                                :max="365"
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                icon="account">
                            </b-numberinput>                    
                        </div>
                    </div>

                    <div class="columns">
                         <div class="column is-6">
                            Shift Edit Allowed Days - Staff
                        </div>
                        <div class="column is-6">
                            <b-numberinput placeholder="Seq#" compact
                                v-model="settingsValues.shiftStaffAllowDays.value"
                                class="lottery-seq-input"
                                size="is-small"
                                :min=3
                                :max=365
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                icon="account">
                            </b-numberinput>                    
                        </div> 
                    </div> 

                    <div class="columns">
                         <div class="column is-6">
                            Shift Number to Email on Update
                        </div>
                        <div class="column is-6">
                            <b-numberinput placeholder="Seq#" compact
                                v-model="settingsValues.shiftNumberToEmailUpdate.value"
                                class="lottery-seq-input"
                                size="is-small"
                                :min=0
                                :max=3
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                icon="account">
                            </b-numberinput>                    
                        </div> 
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Shift Update Notification <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input :disabled="settingsValues.shiftNumberToEmailUpdate.value < 1" type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.shiftUpdateNotificationEmailAddresses.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Security Override Password
                            <b-tooltip label="Use Password of Minimum length 4">
                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                            </b-tooltip>
                        </div>
                        <div class="column is-6">
                            <b-input type="text"
                                placeholder="Minimum 4 Characters"
                                v-model="settingsValues.ownerOverridePassword.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>

                    <!-- <div class="columns">
                        <div class="column is-6">
                            Instant Lottery State
                        </div>
                        <div class="column is-6">    
                            <b-select v-model="settingsValues.lotteryStateId.value">
                                <option v-for="state in states" :key="state.id + Math.random()" :value="state.id">{{ state.name }}</option>
                            </b-select>
                        </div>
                    </div> -->

                    <div class="columns">                  
 
                        <div class="column is-6">
                            Track Instant Lottery on Shift
                        </div>
                        <div class="column is-6">    
                            <b-switch 
                                true-value="1"
                                false-value="0"
                                size="is-small"
                                v-model="settingsValues.trackInstantLotteryBooks.value"
                                type="is-success">                                    
                            </b-switch>
                        </div>
                    </div>

                    <!-- <div class="columns" v-if="settingsValues.trackInstantLotteryBooks.value == 1">                  
 
                        <div class="column is-6">
                            Lottery Ticket Sale Direction
                        </div>
                        <div class="column is-6">
                            <b-radio
                                v-model="settingsValues.lotteryInsideSaleDirection.value"
                                name="name"
                                size="is-small"
                                default
                                native-value="0">
                                Zero to Last
                            </b-radio>
                            <b-radio
                                v-model="settingsValues.lotteryInsideSaleDirection.value"
                                name="name"
                                size="is-small"
                                native-value="1">
                                Last to Zero
                            </b-radio> 
                        </div>
                    </div> -->

                    <div class="columns">                  
 
                        <div class="column is-6">
                            Enable Lottery Audit
                        </div>
                        <div class="column is-6">    
                            <b-switch 
                                true-value="1"
                                false-value="0"
                                size="is-small"
                                v-model="settingsValues.showLotteryAudit.value"
                                type="is-success">                                    
                            </b-switch>
                        </div>
                    </div>

                    <div class="columns">                  
        
                        <div class="column is-6">
                            Enable Credit/Debit Net Deposit Report
                        </div>
                        <div class="column is-6">    
                            <b-switch 
                                true-value="1"
                                false-value="0"
                                size="is-small"
                                v-model="settingsValues.showCreditDebitNetDeposit.value"
                                type="is-success">
                            </b-switch>
                        </div>                
                        <div class="column is-3">
                            
                        </div>
                        <div class="column is-3">    
                            
                        </div>                                              
                    </div>

                    <!-- <div class="columns">                  
        
                        <div class="column is-6">
                            Separated Monthly End Inventory
                        </div>
                        <div class="column is-6">    
                            <b-switch 
                                true-value="1"
                                false-value="0"
                                size="is-small"
                                v-model="settingsValues.separatedMonthendInventory.value"
                                type="is-success">
                            </b-switch>
                        </div>                
                        <div class="column is-3">
                            
                        </div>
                        <div class="column is-3">    
                            
                        </div>                                              
                    </div> -->

                    <div class="columns">
                        <div class="column is-6">
                            Profit Margin Setting Authorized <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.profitMarginAuthorizedEmails.value"
                                >
                            </b-input>
                        </div>                                    
                    </div> 

                                        
                </div>
                <div class="column is-6">
                    <div class="columns">
                        <div class="column is-6">
                            Payroll Week Begin Day
                        </div>
                        <div class="column is-6">
                            <b-select v-model="settingsValues.payrollWeekBeginDayNumber.value">
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>
                            </b-select>
                        </div>                                    
                    </div>
                    
                    <div class="columns">
                         <div class="column is-6">
                            Payroll Weekly Budgeted Hours
                        </div>
                        <div class="column is-6">
                            <b-numberinput placeholder="Seq#" compact
                                v-model="settingsValues.payrollBudgetedHours.value"
                                class="lottery-seq-input"
                                size="is-small"
                                :min=0
                                :max=1000
                                default="0"
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                icon="account">
                            </b-numberinput>                    
                        </div> 
                    </div>

                    <div class="columns">
                         <div class="column is-6">
                            Pay Period Offset days
                        </div>
                        <div class="column is-6">
                            <b-numberinput placeholder="Seq#" compact
                                v-model="settingsValues.payrollNumDaysAfterPayPeriodToPay.value"
                                class="lottery-seq-input"
                                size="is-small"
                                :min=0
                                :max=365
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                icon="account">
                            </b-numberinput>                    
                        </div> 
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Payroll Authorized <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.payrollAuthorizedEmails.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Bank Account Authorized <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.bankAccountAuthorizedEmails.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>

                    <div class="columns">
                        <div class="column is-6">
                            Pricebook Update Authorized Staff <br>(Email Addresses)
                        </div>
                        <div class="column is-6">
                            <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                v-model="settingsValues.priceBookAuthorizedEmails.value"
                                >
                            </b-input>
                        </div>                                    
                    </div>

                    <div class="columns">
                        <div class="column is-12">
                            <div class="panel">
                                <div class="content">
                                    <div class="panel-heading">
                                        Commission Percentage
                                    </div>
                                    <div class="panel-content ml-3 mt-3">
                                        
                                        <div class="columns">
                                            <div class="column is-6">
                                                Enable Manager Commission
                                            </div>
                                            <div class="column is-6">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.showCommission.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>                                    
                                        </div>

                                        <div class="columns" v-if="settingsValues.showCommission.value == 1">
                                            <div class="column is-6">
                                                Commission Authorized <br>(Email Addresses)
                                            </div>
                                            <div class="column is-6">
                                                <b-input  type="textarea" placeholder="Comma Separated Email Addresses" compact
                                                    v-model="settingsValues.commissionAuthorizedEmails.value"
                                                    >
                                                </b-input>
                                            </div>                                    
                                        </div>

                                        <div class="columns" v-if="settingsValues.showCommission.value == 1">
                                            <div class="column is-6">
                                                Store Commission (%)
                                            </div>
                                            <div class="column is-6">
                                                <b-numberinput placeholder="Commission %" compact
                                                    v-model="settingsValues.commissionStorePercentage.value"
                                                    class="lottery-seq-input"
                                                    size="is-small"
                                                    min="0"
                                                    max="100"
                                                    step="0.01"
                                                    :controls="showNumberControls"
                                                    controls-alignment="center"
                                                    controls-position="compact"
                                                    controls-rounded
                                                    @focus="$event.target.select()"
                                                    @wheel.native="$event.target.blur()" 
                                                    icon="account">
                                                </b-numberinput>
                                                <!-- <b-input
                                                    v-model="settingsValues.commissionStorePercentage.value"
                                                    size="is-small"
                                                    class="fees-input"
                                                    @focus="$event.target.select()"
                                                    >

                                                </b-input> -->
                                            </div>                                    
                                        </div>

                                        <div class="columns" v-if="settingsValues.showCommission.value == 1">
                                            <div class="column is-6">
                                                Sales Increase Commission (%)
                                            </div>
                                            <div class="column is-6">
                                                <b-numberinput placeholder="Commission %" compact
                                                    v-model="settingsValues.commissionSalesIncreasePercentage.value"
                                                    class="lottery-seq-input"
                                                    size="is-small"
                                                    min="0"
                                                    max="100"
                                                    step="0.01"
                                                    :controls="showNumberControls"
                                                    controls-alignment="center"
                                                    controls-position="compact"
                                                    controls-rounded
                                                    @focus="$event.target.select()"
                                                    @wheel.native="$event.target.blur()" 
                                                    icon="account">
                                                </b-numberinput>
                                                <!-- <b-input
                                                    v-model="settingsValues.commissionSalesIncreasePercentage.value"
                                                    size="is-small"
                                                    class="fees-input"
                                                    @focus="$event.target.select()"
                                                    >

                                                </b-input> -->
                                            </div>                                    
                                        </div> 

                                        <div class="columns" v-if="settingsValues.showCommission.value == 1">
                                            <div class="column is-6">
                                                Manager Commission (%) of Sales Commission
                                            </div>
                                            <div class="column is-6">
                                                <b-numberinput placeholder="Commission %" compact
                                                    v-model="settingsValues.commissionManagerPercentage.value"
                                                    class="lottery-seq-input"
                                                    size="is-small"
                                                    min="0"
                                                    max="100"
                                                    step="0.01"
                                                    :controls="showNumberControls"
                                                    controls-alignment="center"
                                                    controls-position="compact"
                                                    controls-rounded
                                                    @focus="$event.target.select()"
                                                    @wheel.native="$event.target.blur()" 
                                                    icon="account">
                                                </b-numberinput>
                                                <!-- <b-input
                                                    v-model="settingsValues.commissionManagerPercentage.value"
                                                    size="is-small"
                                                    class="fees-input"
                                                    @focus="$event.target.select()"
                                                    >

                                                </b-input> -->
                                            </div>                                    
                                        </div>

                                        <div class="columns" v-if="settingsValues.showCommission.value == 1">
                                            <div class="column is-6">
                                                Assistant Manager Commission (%) of Sales Commission
                                            </div>
                                            <div class="column is-6">
                                                <b-numberinput placeholder="Commission %" compact
                                                    v-model="settingsValues.commissionAssistantManagerPercentage.value"
                                                    class="lottery-seq-input"
                                                    size="is-small"
                                                    min="0"
                                                    max="100"
                                                    step="0.01"
                                                    :controls="showNumberControls"
                                                    controls-alignment="center"
                                                    controls-position="compact"
                                                    controls-rounded
                                                    @focus="$event.target.select()"
                                                    @wheel.native="$event.target.blur()" 
                                                    icon="account">
                                                </b-numberinput>
                                                <!-- <b-input
                                                    v-model="settingsValues.commissionAssistantManagerPercentage.value"
                                                    size="is-small"
                                                    class="fees-input"
                                                    @focus="$event.target.select()"
                                                    >

                                                </b-input> -->
                                            </div>                                    
                                        </div>

                                        <!-- <div class="columns">
                                            <div class="column is-6">
                                                Employee Commission (%)
                                            </div>
                                            <div class="column is-6">
                                                <b-numberinput placeholder="Commission %" compact
                                                    v-model="settingsValues.commissionEmployeePercentage.value"
                                                    class="lottery-seq-input"
                                                    size="is-small"
                                                    :min="0"
                                                    :max="100"
                                                    :controls="showNumberControls"
                                                    controls-alignment="center"
                                                    controls-position="compact"
                                                    controls-rounded
                                                    @focus="$event.target.select()"
                                                    @wheel.native="$event.target.blur()" 
                                                    icon="account">
                                                </b-numberinput>
                                            </div>                                    
                                        </div>                                                                                 -->
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="panel">
                                <div class="content">
                                    <div class="panel-heading">
                                        Accountant Report Options
                                    </div>
                                    <div class="panel-content ml-3 mt-3">
                                        <div class="columns">
                                            <div class="column is-4">
                                                Show Merchandise Sales
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowSales.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>
                                            <div class="column is-4">
                                                Show Payment
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowPayment.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>                                    
                                        </div>
                                        <div class="columns">
                                            <div class="column is-4">
                                                Show Lottery
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowLottery.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>
                                            <div class="column is-4">
                                                Show Inventory Purcahse
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowInventoryPurchase.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column is-4">
                                                Show Fuel Invoice
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowFuelInvoice.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>
                                            <div class="column is-4">
                                                Show Fuel Delivery
                                            </div>
                                            <div class="column is-2">
                                                <b-switch 
                                                    true-value="1"
                                                    false-value="0"
                                                    size="is-small"
                                                    v-model="settingsValues.accountantShowFuelDelivery.value"
                                                    type="is-success">
                                                </b-switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>

            </div>

            <b-notification 
            type="is-info is-light" 
            aria-close-label="Close notification">
            <!-- Use comma to separate multiple email addresses.<br> -->
            Please log off and log back in for the changes to take effect.
        </b-notification>                           
        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button> 
        </footer>
    </form>
    <not-authorized v-else></not-authorized>
    </div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import axios from 'axios'
import router from '../../../../router'
import NotAuthorized from '../../../../components/app/NotAuthorized.vue'

export default {

    beforeRouteLeave (to, from, next) {

        if (UserStore.getters.getIsEditing) {
            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => next()
            })
        } else {
            next()
        }
    },

    components: {
        NotAuthorized
    },

    metaInfo: {
        title: 'Store Settings'
    },

    data() {
        return {
            user: [],
            storeSettings: [],
            newSettings: [],
            existingSettings: [],
            storeSettingsOptions: UserStore.getters.getStoreSettingsOptions,
            settingsValues: UserStore.getters.getStoreSettingsValues,
            showNumberControls: true,
            vendors: [],
            vendorTemp: {},
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            isDisabled: false,
            action: '',
            storeId: 0,
            role: '',
            states: [],
            deleteAllowed: true,
        }
    },

    methods: {

        submitForm() {
			if (  UserStore.getters.getActiveStore.storeId ) {
                let postComplete = true
                this.storeSettingsOptions.forEach( (item) => {

                     var setting = this.storeSettings.find(o => o.key === item.key)
                    if (setting === undefined) {
                        var newItem = {
                            "id": 0,
                            "key": item.key,
                            "value": this.settingsValues[item.field].value,
                        }
                        this.newSettings.push(newItem)                           
                    } else {
                        this.existingSettings.push({
                            "id": this.settingsValues[item.field].id,
                            "key": this.settingsValues[item.field].key,
                            "value": this.settingsValues[item.field].value,
                        })
                    }
                })
                
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/settings'

                if (this.newSettings.length > 0) {
                    axios.post(url, 
                        this.newSettings,
                        {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken					},				
                        })
                    .then( response => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.status === 200) {
                            postComplete = true
                        }
                        
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)

                        postComplete = false
                        this.$buefy.notification.open({
                                message: 'Failed to update Store Settings ' + err.response.status,
                                type: 'is-danger',
                                duration: 1000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    })
                }

                if (this.existingSettings.length > 0) {
                    axios.put(url, 
                        this.existingSettings,
                        {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken					},				
                        })
                    .then( response => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        if (response.status === 200) {
                            postComplete = true
                        }

                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)

                        postComplete = false
                        this.$buefy.notification.open({
                                message: 'Failed to update Store Settings ' + err.response.status,
                                type: 'is-danger',
                                duration: 1000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    })
                }
                if (postComplete) {
                    this.$buefy.dialog.alert({
                        title: 'Store Settings Changes Saved',
                        message: 'Requires to log out and log back in for the changes to take effect.',
                        confirmText: 'Logout',
                        onConfirm: () => this.logout() 
                    })
                } 
			}
        },        

        logout() {
            UserStore.commit("setIsEditing",false)
            UserStore.commit('userLogout')
        },

        fetchSettings() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/settings'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.storeSettings = response.data.data
                        this.setSettingsValues()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchVendors() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/vendors'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.vendors = this.sortArrayByProp(response.data.data, "name")
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchStates() {
            
            var url = process.env.VUE_APP_API_ROOT_URL + "/global/states/" + UserStore.getters.getStore.country

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.states = this.sortArrayByProp(response.data.data, "name")

                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })            
            

        },

        setSettingsValues() {

            this.storeSettingsOptions.forEach( (item) => {
                this.settingsValues[item.field] = this.storeSettings.find(o => o.key === item.key)

                if (this.settingsValues[item.field] === undefined) {
                    this.settingsValues[item.field] = {
                        "id": 0,
                        "key": item.key,
                        "value": ""
                    }

                    if (item.type == "number") {
                        this.settingsValues[item.field].value = 0
                    }
                    if (item.type == "string") {
                        this.settingsValues[item.field].value = ""
                    }
                }

                if (item.type == "number" && this.settingsValues[item.field] !== undefined) {
                    this.settingsValues[item.field].value = parseFloat(this.settingsValues[item.field].value)
                }

                if (this.settingsValues.lotteryInsideSaleDirection.value == "") {
                    this.settingsValues.lotteryInsideSaleDirection.value = 0
                }

            })

        },

        setSettingsValues1() {

            if (this.storeSettings.length == 0) {
                this.settingsValues = {
                    "ccVendorId": {"id":1,"key":"CC_VENDOR_ID","value": "0"},
                    "showLotteryAudit": {"id":1,"key":"LOTTERY_AUDIT_OPTION_ENABLE","value": "0"},
                    "lotteryStateId": {"id":1,"key":"LOTTERY_STATE_ID","value": "1"},
                    "shiftManagerAllowDays": {"id":1,"key":"SHIFT_EDIT_MANAGER_ALLOWED_DAYS","value": 32},
                    "shiftStaffAllowDays": {"id":1,"key":"SHIFT_EDIT_STAFF_ALLOWED_DAYS","value": 3},
                    "shiftUnlockEmailList": {"id":1,"key":"SHIFT_UNLOCK_EMAIL_LIST","value": ""},
                    "shiftUnlockEmailEnabled": {"id":1,"key":"SHIFT_UNLOCK_EMAIL_ENABLED","value": 0},
                    "showCommission": {"id":1,"key":"COMMISSION_OPTION_ENABLE","value": 0},
                    "commissionStorePercentage": {"id":1,"key":"COMMISSION_STORE_PERCENTAGE","value": 0},
                    "commissionSalesIncreasePercentage": {"id":1,"key":"COMMISSION_INCREASE_SALES_PERCENTAGE","value": 0},
                    "commissionManagerPercentage": {"id":1,"key":"COMMISSION_MANAGER_PERCENTAGE","value": 0},
                    "commissionAssistantManagerPercentage": {"id":1,"key":"COMMISSION_ASSISTANT_MANAGER_PERCENTAGE","value": 0},
                    "commissionEmployeePercentage": {"id":1,"key":"COMMISSION_EMPLOYEE_PERCENTAGE","value": 0},
                    "payrollWeekBeginDayNumber": {"id":1,"key":"PAYROLL_WEEK_BEGIN_DAY_NUMBER","value": 1},
                    "payrollBudgetedHours": {"id":1,"key":"PAYROLL_BUDGETED_HOURS","value": 0},
                    "payrollNumDaysAfterPayPeriodToPay": {"id":1,"key":"PAYROLL_NUMBER_OF_DAYS_AFTER_TO_PAY","value": 0},
                    "bankAccountAuthorizedEmails": {"id":1,"key":"BANK_ACCOUNT_AUTHORIZED_EMAILS","value": 0},
                }
            } else {
                this.settingsValues.ccVendorId = this.storeSettings.find(o => o.key === 'CC_VENDOR_ID')
                this.settingsValues.lotteryStateId = this.storeSettings.find(o => o.key === 'LOTTERY_STATE_ID')
                this.settingsValues.showLotteryAudit = this.storeSettings.find(o => o.key === 'LOTTERY_AUDIT_OPTION_ENABLE')

                this.settingsValues.shiftManagerAllowDays = this.storeSettings.find(o => o.key === 'SHIFT_EDIT_MANAGER_ALLOWED_DAYS')
                this.settingsValues.shiftManagerAllowDays.value = parseInt(this.settingsValues.shiftManagerAllowDays.value)

                this.settingsValues.shiftStaffAllowDays = this.storeSettings.find(o => o.key === 'SHIFT_EDIT_STAFF_ALLOWED_DAYS')
                this.settingsValues.shiftStaffAllowDays.value = parseInt(this.settingsValues.shiftStaffAllowDays.value)

                this.settingsValues.shiftUnlockEmailList = this.storeSettings.find(o => o.key === 'SHIFT_UNLOCK_EMAIL_LIST')

                this.settingsValues.shiftUnlockEmailEnabled = this.storeSettings.find(o => o.key === 'SHIFT_UNLOCK_EMAIL_ENABLED')
                this.settingsValues.shiftUnlockEmailEnabled.value = parseInt(this.settingsValues.shiftUnlockEmailEnabled.value)

                this.settingsValues.commissionStorePercentage = this.storeSettings.find(o => o.key === 'COMMISSION_STORE_PERCENTAGE')
                this.settingsValues.commissionStorePercentage.value = parseInt(this.settingsValues.commissionStorePercentage.value)

                this.settingsValues.commissionSalesIncreasePercentage = this.storeSettings.find(o => o.key === 'COMMISSION_INCREASE_SALES_PERCENTAGE')
                this.settingsValues.commissionSalesIncreasePercentage.value = parseInt(this.settingsValues.commissionSalesIncreasePercentage.value)

                this.settingsValues.commissionManagerPercentage = this.storeSettings.find(o => o.key === 'COMMISSION_MANAGER_PERCENTAGE')
                this.settingsValues.commissionManagerPercentage.value = parseInt(this.settingsValues.commissionManagerPercentage.value)

                this.settingsValues.commissionAssistantManagerPercentage = this.storeSettings.find(o => o.key === 'COMMISSION_ASSISTANT_MANAGER_PERCENTAGE')
                this.settingsValues.commissionAssistantManagerPercentage.value = parseInt(this.settingsValues.commissionAssistantManagerPercentage.value)

                this.settingsValues.commissionEmployeePercentage = this.storeSettings.find(o => o.key === 'COMMISSION_EMPLOYEE_PERCENTAGE')
                this.settingsValues.commissionEmployeePercentage.value = parseInt(this.settingsValues.commissionEmployeePercentage.value)

                this.settingsValues.payrollWeekBeginDayNumber = this.storeSettings.find(o => o.key === 'PAYROLL_WEEK_BEGIN_DAY_NUMBER')
                this.settingsValues.payrollWeekBeginDayNumber.value = parseInt(this.settingsValues.payrollWeekBeginDayNumber.value)

                this.settingsValues.payrollBudgetedHours = this.storeSettings.find(o => o.key === 'PAYROLL_WEEK_BEGIN_DAY_NUMBER')
                this.settingsValues.payrollBudgetedHours.value = parseInt(this.settingsValues.payrollWeekBeginDayNumber.value)

                this.settingsValues.payrollNumDaysAfterPayPeriodToPay = this.storeSettings.find(o => o.key === 'PAYROLL_WEEK_BEGIN_DAY_NUMBER')
                this.settingsValues.payrollNumDaysAfterPayPeriodToPay.value = parseInt(this.settingsValues.payrollWeekBeginDayNumber.value)

                this.settingsValues.lotteryInsideSaleDirection = this.storeSettings.find(o => o.key === 'LOTTERY_INSIDE_SALE_DIRECTION')
                this.settingsValues.lotteryInsideSaleDirection.value = parseInt(this.settingsValues.lotteryInsideSaleDirection.value)
                if (this.settingsValues.lotteryInsideSaleDirection.value == "") {
                    this.settingsValues.lotteryInsideSaleDirection.value = 0
                }
            }
        },

        getSetting(setting) {
          return UserStore.getters.getStoreSettingsValue(setting).value
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },

        saveRecord() {
            this.isLoading = true
            if (this.vendorTemp.id > 0) {
                this.updateRecord()
            } else {
                this.addRecord()
            }
        },

        closeDialog() {
            UserStore.commit("setIsEditing",false)
            router.push({ path: '/' })
        }

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        profitCalculationMethodMessage() {
            if (this.settingsValues.profitCalculationMethod.value == "Margin") {
                return "eg:- Cost $100, 20% Margin, Sales Price will be $ $125. Margin is 20% of sales price."
            } else {
                return "eg:- Cost $100, 20% Markup, Sales Price will be $120, Markup is 20% of cost."
            }    
        }
    },

    // watch: {
    //     settingsValues: {
    //         handler(){
    //             console.log("Data Changed", this.setIsEditing)
    //             UserStore.commit("setIsEditing",true)
    //         },
    //         deep: true,
    //         immediate: false
    //     }
    // },

    mounted() {
        if (! this.getSettingUserAuthorized('settingsAuthorizedEmails')) {
            return
        }

        this.user = this.getUser()
        // this.storeSettingsOptions = UserStore.getters.getStoreSettingsOptions
        this.fetchSettings()      
        this.fetchVendors()
        this.fetchStates()
        UserStore.commit("setIsEditing",true)
    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 4em;
}

.btn-label-text {
    padding-left: 5px;
}
.lottery-seq-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 80px;
    /* border-color: gold; */
    border-width: 2px;
}
.lottery-seq-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.fees-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 100px;
    /* border-color: gold; */
    border-width: 2px;
}

.fees-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    /* border-color: gray; */
    border-width: 1px;
}

input {
    border-color: gold;
}
</style>